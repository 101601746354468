import { axiosProxy } from "../../utils/axiosProxy";
import { actionType } from "../../constants/WorkingTime";
import { getRefreshToken } from "actions/User";

export const fetchGeneralTasksWorkingTimesList = taskIds => async dispatch => {
  try {
    dispatch({ type: actionType.GET_WORKING_TIME_START });

    const { data } = await axiosProxy({
      method: "GET",
      params: {
        taskId: taskIds.join(", "),
        unpaged: true
      },
      url: "/general_task/working_times"
    });

    dispatch({
      type: actionType.GET_WORKING_TIME,
      payload: data
    });

    return data;
  } catch (error) {
    dispatch({
      type: actionType.GET_WORKING_TIME_FAIL,
      payload: error
    });
  }
};

export const fetchGeneralTasksClockInList = taskIds => async dispatch => {
  try {
    dispatch({ type: actionType.GET_CLOCK_IN_START });
    const { data } = await axiosProxy({
      method: "GET",
      params: {
        taskIds: taskIds.join(", "),
        unpaged: true
      },
      url: "/general_task/working_times/clock_in"
    });

    dispatch({
      type: actionType.GET_CLOCK_IN,
      payload: {
        content: data
      }
    });

    return data;
  } catch (e) {
    dispatch({
      type: actionType.GET_CLOCK_IN_FAIL,
      payload: e
    });
  }
};

export const fetchGeneralTask = (
  { archived, page, size, search, sort, unpaged = false, filters },
  pageActiveTab,
  workingTimeCompleted
) => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_TASKS_START });
    const params = new URLSearchParams();
    if (archived !== undefined) {
      params.append("archived", archived);
    }
    if (page !== undefined) {
      params.append("page", page);
    }
    if (size !== undefined) {
      params.append("size", size);
    }
    if (search !== undefined) {
      params.append("search", search);
    }
    if (unpaged !== undefined) {
      params.append("unpaged", unpaged);
    }
    const sortCriteria = sort ? [...sort] : [];
    if (!sortCriteria.includes("expired,desc")) {
      sortCriteria.unshift("expired,desc");
    }
    sortCriteria.forEach(sortCriterion => {
      params.append("sort", sortCriterion);
    });
    if (Array.isArray(filters)) {
      filters.forEach(([filter, value]) => {
        const from = value.slice(0, 10);
        const to = value.slice(-10);
        return filter !== "seasons"
          ? params.append(filter, value)
          : (params.append("from", `${from}T00:00:00`),
            params.append("to", `${to}T23:59:59`));
      });
    }
    params.append("workingTimeCompleted", workingTimeCompleted);

    const res = await axiosProxy({
      method: "GET",
      url: "/general_task",
      params
    });

    await dispatch({
      type: actionType.SET_TASKS,
      payload: res.data
    });

    if (res && res.data) {
      const ids = res.data.content.map(i => i.id);
      pageActiveTab === "COMPLETED"
        ? ids.length && (await dispatch(fetchGeneralTasksWorkingTimesList(ids)))
        : ids.length && (await dispatch(fetchGeneralTasksClockInList(ids)));
    }

    return res.data;
  } catch (error) {
    dispatch({
      type: actionType.FETCH_TASKS_FAIL,
      payload: error
    });
  }
};

export const addGeneralTasksClockIn = data => async () => {
  try {
    const sendData = data.map(item => ({
      ...item,
      areas: item.areas.map(a => ({ areaId: a }))
    }));

    const res = await axiosProxy({
      method: "POST",
      url: "/general_task/working_times/clock_in",
      data: sendData
    });

    return res;
  } catch (e) {}
};

export const updateClockOut = formData => async () => {
  try {
    await axiosProxy({
      method: "PUT",
      url: "/general_task/working_times/clock_out",
      data: formData
    });
  } catch (e) {}
};

export const updateWorkingTime = item => async (dispatch, getState) => {
  const { id } = item;
  try {
    dispatch({
      type: actionType.UPDATE_WORKING_TIME_START,
      payload: { id }
    });

    const res = await axiosProxy({
      method: "PUT",
      data: item,
      url: `/general_task/working_times/${id}`
    });

    dispatch({
      type: actionType.UPDATE_WORKING_TIME,
      payload: res.data
    });

    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.UPDATE_WORKING_TIME_FAIL,
      payload: e
    });

    e.response?.status === 401 &&
      dispatch(getRefreshToken()).then(dispatch(updateWorkingTime(id, item)));

    return e.response;
  }
};

export const updateWorkingTimeStatus = wtId => async dispatch => {
  try {
    dispatch({
      type: actionType.UPDATE_WORKING_TIME_STATUS_START
    });

    const res = await axiosProxy({
      method: "PUT",
      data: { workingTimeIds: wtId },
      url: `/general_task/working_times/approve`
    });
    dispatch({
      type: actionType.UPDATE_WORKING_TIME_STATUS,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.UPDATE_WORKING_TIME_STATUS_FAIL,
      payload: e
    });
  }
};

export const createWorkingTime = formData => async () => {
  try {
    const res = await axiosProxy({
      method: "POST",
      url: "/general_task/working_times",
      data: formData
    });

    return res.data;
  } catch (e) {}
};

export const deleteWorkingTime = id => async () => {
  try {
    const res = await axiosProxy({
      method: "DELETE",
      url: `/general_task/working_times/${id}`
    });

    return res;
  } catch (e) {}
};

export const deleteWorkingTimes = ids => async () => {
  try {
    const res = await axiosProxy({
      method: "DELETE",
      url: `/general_task/working_times/batch?ids=${ids.join("&ids=")}`
    });

    return res;
  } catch (error) {}
};

export const setActiveItemId = id => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_ITEM_ID, payload: { id } });
};
export const setActiveTab = activeTab => dispatch => {
  dispatch({ type: actionType.SET_ACTIVE_TAB, payload: activeTab });
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.CLEAR_ERROR });
