export const actionType = {
  FETCH_TASKS_START: "WORKING_TIME/FETCH_TASKS_START",
  SET_TASKS: "WORKING_TIME/SET_TASKS",
  FETCH_TASKS_FAIL: "WORKING_TIME/FETCH_TASKS_FAIL",

  GET_WORKING_TIME_START: "WORKING_TIME/GET_WORKING_TIME_START",
  GET_WORKING_TIME: "WORKING_TIME/GET_WORKING_TIME",
  GET_WORKING_TIME_FAIL: "WORKING_TIME/GET_WORKING_TIME_FAIL",

  GET_CLOCK_IN_START: "CLOCK_IN/GET_CLOCK_IN_START",
  GET_CLOCK_IN: "CLOCK_IN/GET_CLOCK_IN",
  GET_CLOCK_IN_FAIL: "CLOCK_IN/GET_CLOCK_IN_FAIL",

  UPDATE_WORKING_TIME_START: "WORKING_TIME/UPDATE_WORKING_TIME_START",
  UPDATE_WORKING_TIME: "WORKING_TIME/UPDATE_WORKING_TIME",
  UPDATE_WORKING_TIME_FAIL: "WORKING_TIME/UPDATE_WORKING_TIME_FAIL",

  UPDATE_WORKING_TIME_STATUS_START:
    "WORKING_TIME_STATUS/UPDATE_WORKING_TIME_STATUS_START",
  UPDATE_WORKING_TIME_STATUS: "WORKING_TIME_STATUS/UPDATE_WORKING_TIME_STATUS",
  UPDATE_WORKING_TIME_STATUS_FAIL:
    "WORKING_TIME_STATUS/UPDATE_WORKING_TIME_STATUS_FAIL",

  SET_ACTIVE_ITEM_ID: "CHEMICALS/SET_ACTIVE_ITEM_ID",
  SET_ACTIVE_TAB: "CHEMICALS/SET_ACTIVE_TAB",
  CLEAR_ERROR: "CHEMICALS/CLEAR_ERROR"
};
