import { uniqBy } from "lodash";
import { createSelector } from "reselect";

const workingTimesDataSelector = state => state.workingTimes;
const tasksSelector = state => state.workingTimes.tasks;
const workingTimesSelector = state => state.workingTimes.workingTimes;
const clockInSelector = state => state.workingTimes.clockIn;
export const taskList = state => state.newTasks.taskIDs;

export const formaterData = (tasks, workingTimes) => {
  const { content: tasksContent, ...rest } = tasks;
  const { content: workingTimesContent } = workingTimes;

  const calculateWorkingTime = wt => {
    const start = new Date(wt.startAt);
    const end = new Date(wt.endAt);
    const diffInMs = end - start;
    const lunchIntervalInMs = (wt.lunchInterval || 0) * 60 * 1000;
    const diffInHours = (diffInMs - lunchIntervalInMs) / (1000 * 60 * 60);

    return diffInHours;
  };

  const contentUpdated = !!tasksContent.length
    ? tasksContent.map(task => {
        const taskWorkingTimes = workingTimesContent.filter(
          ({ generalTaskId }) => generalTaskId === task.id
        );

        const totalWorkingTime = taskWorkingTimes.reduce((prev, wt) => {
          const diffInHours = calculateWorkingTime(wt);

          return prev + diffInHours;
        }, 0);

        const isWorkingTimesApproved = taskWorkingTimes.every(
          wt => wt.approved
        );

        return {
          id: task.id,
          gtId: `GT-${task.id}`,
          wtId: taskWorkingTimes.map(({ id }) => id),
          taskName: task?.name || "-",
          type: task?.type?.name || "-",
          employees: taskWorkingTimes.map(({ employeeId }) => employeeId),
          createdBy: uniqBy(
            taskWorkingTimes.map(({ createdBy }) => ({
              employeeId: createdBy
            })),
            "employeeId"
          ),
          totalWorkingTime,
          approved: isWorkingTimesApproved,
          buttonWrapper: isWorkingTimesApproved,
          rowInfo: "totalRow",
          ...(taskWorkingTimes.length > 1 && {
            subRows: taskWorkingTimes.map(
              (
                {
                  id,
                  employeeId,
                  createdBy,
                  approved,
                  startAt,
                  endAt,
                  lunchInterval
                },
                index
              ) => ({
                id: task.id,
                approved,
                totalWorkingTime: calculateWorkingTime({
                  startAt,
                  endAt,
                  lunchInterval
                }),
                employees: [employeeId],
                createdBy: [{ employeeId: createdBy }],
                buttonWrapper: approved,
                rowInfo: "subRows",
                wtId: [id],
                rowInfo: index
              })
            )
          })
        };
      })
    : [];

  return {
    ...rest,
    content: contentUpdated
  };
};

export const formaterClockInData = (tasks, clockIn) => {
  const { content: tasksContent, ...tasksRest } = tasks;
  const { content: clockInContent } = clockIn;

  const contentUpdated = !!tasksContent.length
    ? tasksContent.map(task => {
        const taskClockIns = clockInContent.filter(
          ({ generalTaskId }) => generalTaskId === task.id
        );

        return {
          id: task.id,
          gtId: `GT-${task.id}`,
          taskName: task?.name || "-",
          type: task?.type?.name || "-",
          areaIds: [
            ...new Set(
              taskClockIns.flatMap(({ areas }) =>
                areas.map(area => area.areaId)
              )
            )
          ],
          employees: taskClockIns.map(({ employeeId }) => employeeId),
          createdBy: uniqBy(
            taskClockIns.map(({ createdBy }) => ({ employeeId: createdBy })),
            "employeeId"
          ),
          buttonWrapper: "totalRow",
          rowInfo: "totalRow",
          ...(taskClockIns.length > 1 && {
            subRows: taskClockIns.map(({ areas, employeeId, createdBy }) => ({
              id: task.id,
              areaIds: areas.map(({ areaId }) => areaId),
              employees: [employeeId],
              createdBy: [{ employeeId: createdBy }],
              buttonWrapper: "subRows",
              rowInfo: "subRows"
            }))
          })
        };
      })
    : [];

  return {
    ...tasksRest,
    content: contentUpdated
  };
};

export const selectTableStructuredData = createSelector(
  [tasksSelector, workingTimesSelector],
  (tasks, workingTimes) => {
    return formaterData(tasks, workingTimes);
  }
);

export const selectInProgressTableStructuredData = createSelector(
  [tasksSelector, clockInSelector],
  (tasks, clockIn) => {
    return formaterClockInData(tasks, clockIn);
  }
);

export const generalTasksFilterOptions = createSelector([taskList], types => {
  return {
    options: types?.content?.length
      ? types.content.map(item => ({
          key: item,
          label: `GT-${item}`,
          value: item
        }))
      : []
  };
});

export const activeItemSelector = createSelector(
  [workingTimesDataSelector],
  workingTimesData => {
    const {
      activeItem,
      tasks,
      clockIn,
      workingTimes,
      activeTab
    } = workingTimesData;
    if (activeItem.id) {
      const task = tasks.content.find(({ id }) => activeItem.id === id);
      const items =
        activeTab.tab === "IN_PROGRESS"
          ? clockIn.content.filter(
              ({ generalTaskId }) => activeItem.id === generalTaskId
            )
          : workingTimes.content.filter(
              ({ generalTaskId }) => activeItem.id === generalTaskId
            );

      return {
        ...task,
        items
      };
    }

    return null;
  }
);
