import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Segment } from "semantic-ui-react";
import Weather from "../../components/Weather";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import { fetchEmployeesList } from "../../actions/Employee/employees";
import { fetchToken, getOrganisations } from "../../actions/JhonDeere";
import { summaryWithActivateAreasOnlySelector } from "../../selectors/farms";
import FarmsList from "./FarmsList";
import FarmsMap from "./FarmsMap";
import ErrorBoundary from "../../components/ErrorBoundary";
import styles from "./Dashboard.module.css";
import PickedBinsReport from "../../components/PickedBinsReport";
import PickedBinsGroupsReport from "../../components/PickedBinsGroupsReport/PickedBinsGroupsReport";
import { hashToObject } from "utils/hashToObject";

const DEFAULT_LOCATION = [-33.8688, 151.2093];

class Dashboard extends Component {
  state = {
    showMap: false,
    zoom: 16,
    mapOptions: {
      streetViewControl: false,
      mapTypeControlOptions: {
        style: 1,
        position: 11
      },
      mapTypeId: "satellite"
    },
    polygons: [],
    markers: [],
    currentLatLng: {
      lat: DEFAULT_LOCATION[0],
      lng: DEFAULT_LOCATION[1]
    },
    defaultLocation: [...DEFAULT_LOCATION]
  };

  componentDidMount() {
    hashToObject(window.location.href).state &&
      this.props.url &&
      this.props.actions.fetchToken({
        clientRedirectUrl: window.location.href.replace(/\?.*$/g, ""),
        responseUrl: window.location.href
      });
    const { match, actions } = this.props;
    this.getCurrentLocation();
    this.prepareFarmsToView();
    // actions.fetchEmployeesList(true);
    if (match?.path === "/") {
      this.props.navigate("/dashboard", { replace: true });
    }
  }

  redirectToFarm = id => {
    this.props.navigate(`/farms/${id}`);
  };

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.setState({
          currentLatLng: {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          },
          defaultLocation: [position.coords.latitude, position.coords.longitude]
        });
      });
    }
  };

  prepareFarmsToView = async () => {
    const { actions } = this.props;
    this.setState({
      showMap: true
    });
  };

  changeCurrentLatLng = currentLatLng => {
    this.setState({
      currentLatLng
    });
  };

  render() {
    const {
      route,
      location,
      summary,
      constructed,
      isFetching,
      isAreaFetching,
      navigate
    } = this.props;
    const { mapOptions, zoom, currentLatLng, defaultLocation } = this.state;

    return (
      <Layout route={route} location={location} showRouteName>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} computer={12} largeScreen={12}>
              <Segment basic className={styles.promptHolder}>
                {isFetching || isAreaFetching ? (
                  <Loader />
                ) : (
                  <ErrorBoundary>
                    <FarmsMap
                      navigate={navigate}
                      onLatLngChange={this.changeCurrentLatLng}
                      zoom={zoom}
                      currentLatLng={currentLatLng}
                      mapOptions={mapOptions}
                      collections={summary}
                      showMap={true}
                      defaultLocation={defaultLocation}
                    />
                  </ErrorBoundary>
                )}
              </Segment>
            </Grid.Column>
            <Grid.Column mobile={16} computer={4} largeScreen={4}>
              <Segment basic className={styles.promptHolderRight}>
                <ErrorBoundary>
                  <Weather
                    key={`w_${currentLatLng.lat}_${currentLatLng.lng}`}
                    forecast="5days"
                    apikey={process.env.REACT_APP_OPEN_WEATHER_API_UX}
                    type="geo"
                    lat={
                      currentLatLng && currentLatLng.lat
                        ? currentLatLng.lat.toString()
                        : defaultLocation[0]
                    }
                    lon={
                      currentLatLng && currentLatLng.lng
                        ? currentLatLng.lng.toString()
                        : defaultLocation[1]
                    }
                  />
                </ErrorBoundary>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <PickedBinsReport />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <PickedBinsGroupsReport />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {isFetching ? (
                <Loader />
              ) : summary ? (
                summary.map(item => (
                  <ErrorBoundary key={`summ_${item.farm.id}`}>
                    <FarmsList
                      farm={item}
                      redirectToFarm={this.redirectToFarm}
                      constructed={constructed}
                    />
                  </ErrorBoundary>
                ))
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Layout>
    );
  }
}

Dashboard.propTypes = {
  route: PropTypes.object.isRequired,
  match: PropTypes.object,
  actions: PropTypes.object,
  location: PropTypes.object,
  summary: PropTypes.any,
  constructed: PropTypes.any,
  isFetching: PropTypes.bool
};

Dashboard.defaultProps = {
  route: { name: "{%name%}" }
};

function mapStateToProps(state) {
  const {
    areas,
    farms: { isFetching, constructed },
    johnDeere: { url }
  } = state;
  return {
    url,
    isAreaFetching: areas.list.isFetching,
    isFetching,
    summary: summaryWithActivateAreasOnlySelector(state),
    constructed
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { fetchEmployeesList, fetchToken, getOrganisations },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
