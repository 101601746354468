import { actionType } from "../../constants/SprayDiary";
import { history } from "../../store";
import { axiosProxy } from "../../utils/axiosProxy";

export const getMachineryList = () => async (dispatch, getState) => {
  const url = "/machineries";
  const {
    user: { user },
    sprayDiary
  } = getState();
  const clientId = localStorage.getItem("areaClientId");
  if (
    !sprayDiary.machineryList.content?.length ||
    !clientId ||
    clientId !== user.profile.client_id
  ) {
    dispatch({
      type: actionType.GET_MACHINERY_LIST_START,
      meta: {
        callFrom: history.location.pathname,
        user,
        offline: {
          effect: {
            url,
            method: "GET",
            params: {
              unpaged: true
            }
          },
          commit: {
            type: actionType.GET_MACHINERY_LIST,
            meta: {}
          },
          rollback: {
            type: actionType.GET_MACHINERY_LIST_ERROR,
            meta: {}
          }
        }
      }
    });
  }
};

export const getTaskRoute = (
  taskId,
  machineryId,
  timeRanges
) => async dispatch => {
  const url = `/machineries/${machineryId}/tracker`;

  const data = [];

  try {
    dispatch({
      type: actionType.GET_ROUTE_START
    });

    for (const timeRange of timeRanges) {
      const params = new URLSearchParams();
      params.append("from", timeRange.from);
      params.append("to", timeRange.to);
      const res = await axiosProxy({
        method: "GET",
        params,
        url
      });
      if (res.data.length > 0) {
        data.push(res.data);
      }
    }

    await dispatch({
      type: actionType.GET_ROUTE,
      payload: { id: taskId, route: data }
    });
  } catch (error) {
    dispatch({
      type: actionType.GET_ROUTE_ERROR,
      payload: error
    });
  }
};

export const clearRoute = taskId => dispatch => {
  dispatch({
    type: actionType.CLEAR_ROUTE,
    payload: { id: taskId }
  });
};

export const selectMachinery = id => dispatch => {
  dispatch({
    type: actionType.SELECT_MACHINERY,
    payload: id
  });
};

export const clearMachinery = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_MACHINERY
  });
};

export const addAndSelectMachinery = data => dispatch => {
  dispatch({
    type: actionType.ADD_AND_SELECT_MACHINERY,
    payload: data
  });
};

export const setMachineryConc = conc => dispatch => {
  dispatch({
    type: actionType.SET_MACHINERY_CONC,
    payload: conc
  });
};

export const setMachinerySpeed = speed => dispatch => {
  dispatch({
    type: actionType.SET_MACHINERY_SPEED,
    payload: speed
  });
};

export const setNozzle = nozzle => dispatch => {
  dispatch({
    type: actionType.SET_NOZZLE,
    payload: nozzle
  });
};

export const setNozzlesPerSide = nozzlesPerSide => dispatch => {
  dispatch({
    type: actionType.SET_NOZZLES_PER_SIDE,
    payload: nozzlesPerSide
  });
};

export const setGear = gear => dispatch => {
  dispatch({
    type: actionType.SET_GEAR,
    payload: gear
  });
};

export const setRpm = rpm => dispatch => {
  dispatch({
    type: actionType.SET_RPM,
    payload: rpm
  });
};

export const setPressure = pressure => dispatch => {
  dispatch({
    type: actionType.SET_PRESSURE,
    payload: pressure
  });
};

export const setPto = pto => dispatch => {
  dispatch({
    type: actionType.SET_PTO,
    payload: pto
  });
};
