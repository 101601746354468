import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Popup
} from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import { useDispatch } from "react-redux";
import TaskDetails from "./components/TaskDetails";
import ClockDetails from "./components/ClockDetails";
import CollapsibleBlock from "components/CollapsibleBlock";

function InprogressSidebar({
  employees,
  setSubRowsIndex,
  subRowsIndex,
  activeItem = {},
  setClockOutOpen,
  setForWtAdd,
  setSideBarOpen,
  onClose,
  setActiveItemId
}) {
  const dispatch = useDispatch();

  if (!activeItem) return null;

  return (
    <Container fluid className={styles.root}>
      <Grid className={styles.sidebarHeader}>
        <Grid.Row columns={"equal"} verticalAlign="middle">
          <Grid.Column width={13} className={styles.headerColumn}>
            <Header className={styles.name} as="h1">
              {`GT-${activeItem?.id} details`}
            </Header>
          </Grid.Column>
          <Grid.Column className={styles.btnWrapper} width={3} floated="right">
            <Popup
              inverted
              position="bottom center"
              content={"Close"}
              size="tiny"
              trigger={
                <Button
                  onClick={() => {
                    dispatch(setActiveItemId(null));
                    onClose();
                    setSideBarOpen(false);
                  }}
                  className={styles.closeButton}
                  floated="right"
                  icon={<Icon className="close" />}
                  size="small"
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className={styles.infoContainer}>
        <Grid.Row columns={1}>
          <Grid.Column className={styles.generalInfoContainer}>
            <TaskDetails activeItem={activeItem} employees={employees} />
            <CollapsibleBlock
              title="Clock in - Clock out details"
              className={styles.blockContainer}
            >
              {activeItem.items?.map((item, index) => {
                return (
                  <ClockDetails
                    setForWtAdd={setForWtAdd}
                    setClockOutOpen={setClockOutOpen}
                    setSubRowsIndex={setSubRowsIndex}
                    activeItem={item}
                    index={index}
                    subRowsIndex={subRowsIndex}
                  />
                );
              })}
            </CollapsibleBlock>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => (
            setClockOutOpen(true),
            setSubRowsIndex("totalRow"),
            setForWtAdd(true)
          )}
          className={styles.clockoutBtn}
        >
          Clock Out
        </Button>
      </div>
    </Container>
  );
}

export default InprogressSidebar;
