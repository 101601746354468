import { actionType } from "constants/Settings";
import { axiosProxy } from "utils/axiosProxy";
import genericAttachmentUploader from "utils/genericAttachmentUploader";

const url = "/client_settings";

export const fetchSettings = () => async (dispatch, getState) => {
  const {
    user: { user },
    settings
  } = getState();
  const clientId = localStorage.getItem("areaClientId");
  if (
    !settings.data?.allowedGeneralTaskTypeIds?.length ||
    !clientId ||
    clientId !== user.profile.client_id
  ) {
    try {
      const res = await axiosProxy({
        method: "GET",
        params: {},
        url
      });

      await dispatch({
        type: actionType.FETCH_SETTINGS,
        payload: res.data
      });

      return res.data;
    } catch (error) {
      dispatch({
        type: actionType.FETCH_SETTINGS_FAIL,
        payload: error
      });
    }
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) =>
  genericAttachmentUploader({
    url: `${url}/picture/presign_put_url`,
    file,
    startAction: actionType.UPLOAD_SETTING_ATTACHMENT_START,
    successAction: actionType.UPLOAD_SETTING_ATTACHMENT,
    errorAction: actionType.UPLOAD_SETTING_ATTACHMENT_ERROR,
    callBack,
    idReplace,
    data,
    parentId
  });
export const createSettings = data => async dispatch => {
  dispatch({
    type: actionType.ADD_SETTINGS_START
  });
  try {
    const res = await axiosProxy({
      method: "POST",
      url,
      data
    });

    await dispatch({
      type: actionType.ADD_SETTINGS,
      payload: res.data
    });
    return res.data;
  } catch (error) {
    await dispatch({
      type: actionType.ADD_SETTINGS_FAIL,
      payload: error
    });
  }
};
