import { actionType } from "../../constants/GeneralTasks";
import { axiosProxy } from "../../utils/axiosProxy";
import { GeneralTask } from "../../models/general_task.model";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";
import uuid from "uuid/v4";
import { pageChecker } from "actions/HourlyPayroll/hourlyPayroll";
import { getRefreshToken } from "actions/User";
let uniqId;

const url = "/general_task";

export const fetchGeneralTaskList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_LIST_START });
    const res = await axiosProxy({
      method: "GET",
      url,
      params: {
        unpaged: true
      }
    });
    await dispatch({
      type: actionType.FETCH_GENERAL_TASK_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_LIST_FAIL, payload: error });
  }
};
export const fetchSubTaskList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_SUBTASK_LIST_START });
    const res = await axiosProxy({
      method: "GET",
      url: "/general_task/subetasks"
    });
    await dispatch({
      type: actionType.FETCH_SUBTASK_LIST,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_SUBTASK_LIST_FAIL, payload: error });
  }
};

export const fetchGeneralTasks = ({
  page,
  size,
  search,
  includeWorkingTimes,
  includeGeneralBarcodes,
  sort,
  filters = {},
  dateFrom,
  dateTo,
  unpaged = false
}) => async (dispatch, getState) => {
  const {
    areas,
    employee,
    sprayDiary,
    employeeGroups,
    contractors
  } = getState();
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASKS_START });
    const params = new URLSearchParams();

    Object.entries(filters).forEach(([name, value]) => {
      const from = !!value.length && value[0]?.slice(0, 10);
      const to = !!value.length && value[0]?.slice(-10);
      return name !== "seasons"
        ? Array.isArray(value)
          ? value.forEach(val => params.append(name, val))
          : params.append(name, value)
        : (params.append("from", `${from}T00:00:00`),
          params.append("to", `${to}T23:59:59`));
    });

    if (unpaged) {
      params.append("unpaged", "true");
    } else {
      params.append("page", page);
      params.append("size", size);
    }

    if (includeWorkingTimes) params.append("includeWorkingTimes", "true");
    if (includeGeneralBarcodes) params.append("includeGeneralBarcodes", "true");

    if (dateFrom) params.append("from", toStartDateString(dateFrom));
    if (dateTo) params.append("to", toEndDateString(dateTo));

    search && params.append("search", search);

    (sort || ["compositeEnd,asc"]).forEach(field => {
      params.append("sort", field);
    });
    uniqId = uuid();
    const res = await axiosProxy({ method: "GET", params, uniqId, url });
    const newData = res.data.content.map(item => {
      return {
        ...item,
        areas: areas.list.content
          .filter(area => item.areas.some(({ areaId }) => areaId === area.id))
          .map(filtredArea => {
            return {
              area: filtredArea,
              areaId: filtredArea.id
            };
          }),
        assignedTo: employee.list.content
          .filter(employee =>
            item.assignedTo.some(({ employeeId }) => employeeId === employee.id)
          )
          .map(filtredEmployee => {
            return {
              employee: filtredEmployee,
              employeeId: filtredEmployee.id
            };
          }),
        equipments: sprayDiary.machineryList.content
          .filter(machinery =>
            item.equipments.some(
              ({ machineryId }) => machineryId === machinery.id
            )
          )
          .map(filtredMachinery => {
            return {
              machinery: filtredMachinery,
              machineryId: filtredMachinery.id
            };
          })
      };
    });
    if (uniqId === res.config.headers["X-REQUEST-ID"]) {
      dispatch({
        type: actionType.FETCH_GENERAL_TASKS,
        payload: {
          ...res.data,
          content: newData.map(t => new GeneralTask(t))
        }
      });
      dispatch(pageChecker(true));
    }
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASKS_FAIL, payload: error });
  }
};

export const setLocationToState = ({ location }) => dispatch => {
  dispatch({
    type: actionType.SET_LOCATION,
    payload: location
  });
};
export const fetchGeneralTasksNames = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_NAMES_START });

    const res = await axiosProxy({ method: "GET", url: `${url}/names` });
    dispatch({
      type: actionType.FETCH_GENERAL_TASK_NAMES,
      payload: { content: res.data }
    });
  } catch (error) {
    dispatch({
      type: actionType.FETCH_GENERAL_TASK_NAMES_FAIL,
      payload: error
    });
  }
};

export const fetchGeneralTasksIDs = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_IDS_START });

    const res = await axiosProxy({ method: "GET", url: `${url}/ids` });

    dispatch({
      type: actionType.FETCH_GENERAL_TASK_IDS,
      payload: { content: res.data }
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_IDS_FAIL, payload: error });
  }
};

export const fetchGeneralTask = id => async (dispatch, getState) => {
  const {
    areas,
    employee,
    sprayDiary,
    contractors,
    employeeGroups
  } = getState();
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_START });
    const res = await axiosProxy({ method: "GET", url: `${url}/${id}` });

    const newData = {
      ...res.data,
      areas: areas.list.content
        .filter(area => res.data.areas.some(({ areaId }) => areaId === area.id))
        ?.map(filtredArea => {
          return {
            area: filtredArea,
            areaId: filtredArea.id
          };
        }),
      assignedTo: employee.list.content
        .filter(employee =>
          res.data.assignedTo.some(
            ({ employeeId }) => employeeId === employee.id
          )
        )
        ?.map(filtredEmployee => {
          return {
            employee: filtredEmployee,
            employeeId: filtredEmployee.id
          };
        }),
      equipments: sprayDiary.machineryList.content
        .filter(machinery =>
          res.data.equipments.some(
            ({ machineryId }) => machineryId === machinery.id
          )
        )
        ?.map(filtredMachinery => {
          return {
            machinery: filtredMachinery,
            machineryId: filtredMachinery.id
          };
        }),
      workingTimes: res.data.workingTimes?.map(item => {
        return {
          ...item,
          areas: areas.list.content
            .filter(area => item.areas.some(({ areaId }) => areaId === area.id))
            ?.map(filtredArea => {
              return {
                area: filtredArea,
                areaId: filtredArea.id
              };
            }),
          contractor: contractors.list.content.find(
            contractor => item.contractorId === contractor.id
          ),
          employee: employee.list.content.find(
            employee => item.employeeId === employee.id
          ),
          employeesGroup: employeeGroups.list.content.find(
            employeeGroup => item.employeesGroupId === employeeGroup.id
          )
        };
      })
    };
    await dispatch({ type: actionType.FETCH_GENERAL_TASK, payload: newData });
    return newData;
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_FAIL, payload: error });
  }
};

export const deleteGeneralTask = id => async dispatch => {
  try {
    dispatch({ type: actionType.DELETE_GENERAL_TASK_START });

    await axiosProxy({
      method: "DELETE",
      url: `${url}/${id}`
    });

    dispatch({
      type: actionType.DELETE_GENERAL_TASK,
      payload: id
    });
  } catch (error) {
    dispatch({ type: actionType.DELETE_GENERAL_TASK_FAIL, payload: error });
  }
};

export const createGeneralTask = data => async dispatch => {
  try {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_START });

    const res = await axiosProxy({ method: "POST", url, data });

    dispatch({ type: actionType.CREATE_GENERAL_TASK });

    return res;
  } catch (e) {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_FAIL, payload: e });
  }
};

export const updateGeneralTask = (taskID, data) => async dispatch => {
  try {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_START });

    const res = await axiosProxy({
      method: "PUT",
      url: url + `/${taskID}`,
      data
    });

    dispatch({ type: actionType.CREATE_GENERAL_TASK, payload: res.data });

    return res;
  } catch (e) {
    dispatch({ type: actionType.CREATE_GENERAL_TASK_FAIL, payload: e });
  }
};

export const updateGeneralTaskStatus = (
  taskId,
  status = "COMPLETED"
) => async dispatch => {
  await axiosProxy({
    method: "PATCH",
    url: url + `/${taskId}`,
    data: [
      {
        op: "replace",
        path: "/status",
        value: status
      }
    ]
  });

  dispatch({
    type: actionType.UPDATE_GENERAL_TASK_STATUS,
    taskId,
    status
  });
};

export const archiveGeneralTaskWorkingTime = (taskID, id) => async dispatch => {
  try {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_START
    });
    const res = await axiosProxy({
      method: "PUT",
      url: `${url}/working_times/${id}/archive`
    });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_ARCHIVE,
      payload: { id, taskID, res }
    });

    return res;
  } catch (e) {
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_ARCHIVE_WITH_TASK_FAIL,
      payload: e
    });
  }
};

export const fetchEmployeePayrollReport = ({
  from,
  to,
  token
}) => async dispatch => {
  // TODO: remove this code
  try {
    dispatch({ type: actionType.FETCH_PAYROLL_REPORT_START });

    const params = new URLSearchParams();
    const wtParams = new URLSearchParams();
    const barcodesParams = new URLSearchParams();

    if (from) {
      const dateFrom = `${new Date(from).toISOString().substr(0, 10)}T00:00:00`;
      barcodesParams.append("from", dateFrom);
      wtParams.append("startAtAfter", dateFrom);
    }

    if (to) {
      const dateTo = `${new Date(to).toISOString().substr(0, 10)}T23:59:59`;
      barcodesParams.append("to", dateTo);
      wtParams.append("endAtBefore", dateTo);
    }

    barcodesParams.append("unpaged", "true");
    wtParams.append("unpaged", "true");
    wtParams.append("archived", "false");
    params.append("unpaged", "true");

    const {
      data: { content: generalTasks }
    } = await axiosProxy({
      method: "GET",
      url,
      params
    });

    const {
      data: { content: workingTimes }
    } = await axiosProxy({
      method: "GET",
      url: `${url}/working_times`,
      params: wtParams
    });

    const barcodes = await axiosProxy({
      method: "GET",
      url: `/barcodes`,
      params: barcodesParams
    }).then(({ data: { content } }) => {
      return content.filter(data => data.generalTaskId);
    });

    const generalTasksObj = {};

    Object.values(generalTasks).forEach(
      task => (generalTasksObj[task.id] = task)
    );

    workingTimes.forEach(wt => {
      const gtID = wt.generalTaskId;
      // Possibly archived task
      if (!generalTasksObj[gtID]) return;

      if (generalTasksObj[gtID].hasOwnProperty("workingTimes")) {
        generalTasksObj[gtID].workingTimes.push(wt);
      } else {
        generalTasksObj[gtID].workingTimes = [wt];
      }
    });

    barcodes.forEach(bc => {
      const gtID = bc.generalTaskId;
      // Possibly archived task
      if (!generalTasksObj[gtID]) return;

      if (generalTasksObj[gtID].hasOwnProperty("generalBarcodes")) {
        generalTasksObj[gtID].generalBarcodes.push(bc);
      } else {
        generalTasksObj[gtID].generalBarcodes = [bc];
      }
    });

    const res = Object.values(generalTasksObj).filter(
      gt => gt.generalBarcodes || gt.workingTimes
    );

    dispatch({ type: actionType.FETCH_PAYROLL_REPORT, payload: res });

    return { token, data: res };
  } catch (e) {
    dispatch({
      type: actionType.FETCH_PAYROLL_REPORT_FAIL,
      payload: e
    });
  }
};

export const addWorkingTimeToGeneralTask = workingTime => dispatch => {
  dispatch({
    type: actionType.ADD_WORKING_TIME_TO_GENERAL_TASK,
    payload: {
      workingTime
    }
  });
};

export const editWorkingTimeOfGeneralTask = workingTime => dispatch => {
  dispatch({
    type: actionType.EDIT_WORKING_TIME_OF_GENERAL_TASK,
    payload: {
      workingTime,
      generalTaskID: workingTime.generalTaskId,
      workingTimeID: workingTime.id
    }
  });
};

export const clearError = () => dispatch =>
  dispatch({ type: actionType.CLEAR_ERROR });

export const fetchGeneralTasksWorkingTimes = ({
  dateFrom,
  dateTo,
  id,
  filters,
  unpaged = true
}) => async (dispatch, getState) => {
  const { areas, employee, employeeGroups, contractors } = getState();
  try {
    dispatch({ type: actionType.GET_GENERAL_TASK_WORKING_TIME_START });

    const params = new URLSearchParams();

    if (unpaged) {
      params.append("unpaged", "true");
    }
    Object.entries(filters).forEach(
      ([name, value]) => value && params.append(name, value.toString())
    );
    dateFrom && params.append("endAtAfter", `${dateFrom}T00:00:00`);
    dateTo && params.append("endAtBefore", `${dateTo}T23:59:59`);
    const res = await axiosProxy({
      method: "GET",
      params,
      url: "/general_task/working_times"
    });
    const workingTimes = res.data.content?.map(item => {
      return {
        ...item,
        areas: areas.list.content
          ?.filter(area => item.areas.some(({ areaId }) => areaId === area.id))
          ?.map(filtredArea => {
            return {
              area: filtredArea,
              areaId: filtredArea.id
            };
          }),
        contractor: contractors.list.content.find(
          contractor => item.contractorId === contractor.id
        ),
        employee: [
          ...employee.list.content,
          ...employee.archivedList.content
        ].find(employee => item.employeeId === employee.id),
        employeesGroup: employeeGroups.list.content.find(
          employeeGroup => item.employeesGroupId === employeeGroup.id
        )
      };
    });

    dispatch({
      type: actionType.GET_GENERAL_TASK_WORKING_TIME,
      payload: { taskId: id, workingTimes }
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionType.GET_GENERAL_TASK_WORKING_TIME_FAIL,
      payload: e
    });
  }
};
export const deleteGeneralTaskWorkingTime = (taskId, id) => async dispatch => {
  try {
    dispatch({ type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_START });
    const res = await axiosProxy({
      method: "DELETE",
      url: `/general_task/working_times/${id}`
    });

    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE,
      payload: { id, taskId }
    });

    return res;
  } catch (e) {
    e.response?.status === 401 &&
      dispatch(getRefreshToken()).then(
        dispatch(deleteGeneralTaskWorkingTime(id))
      );
    dispatch({
      type: actionType.GENERAL_TASK_WORKING_TIME_DELETE_FAIL,
      payload: e
    });
  }
};

export const fetchTaskTypeList = () => async dispatch => {
  try {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_TYPE_START });
    const res = await axiosProxy({
      method: "GET",
      url: "/general_task/types"
    });
    await dispatch({
      type: actionType.FETCH_GENERAL_TASK_TYPE,
      payload: res.data
    });
  } catch (error) {
    dispatch({ type: actionType.FETCH_GENERAL_TASK_TYPE_FAIL, payload: error });
  }
};

export const getTaskTypePresignLogoUrl = id => async () => {
  try {
    const { data } = await axiosProxy({
      url: `/general_task/attachments/presign_get_logo_url/${id}`
    });

    return data.presignedUrl;
  } catch (e) {
    console.log(e);
  }
};
