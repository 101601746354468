import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import styles from "./index.module.css";
import { connect, useDispatch } from "react-redux";
import {
  fetchTaskTypeList,
  getTaskTypePresignLogoUrl
} from "actions/GeneralTasks/generalTasks";
import { setSelectAddType } from "actions/Tasks";
const TaskTypeModal = ({
  open,
  setOpen,
  data,
  taskTypes,
  activeType,
  navigate
}) => {
  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState();
  const [taskTypesOptions, setTaskTypesOptions] = useState([]);

  const handleClose = () => {
    setOpen(false);
    setSelectedType(activeType?.value);
  };

  useEffect(() => {
    setSelectedType(activeType?.value);
  }, [activeType?.value]);

  useEffect(() => {
    !taskTypes.length && dispatch(fetchTaskTypeList());
  }, []);

  useEffect(() => {
    (async () => {
      const options = await Promise.all(
        taskTypes
          .filter(({ id }) => data.allowedGeneralTaskTypeIds.includes(id))
          .map(async type => {
            const image = await dispatch(getTaskTypePresignLogoUrl(type.id));
            return { id: type.id, name: type.name, image };
          })
      );

      setTaskTypesOptions(options.sort((a, b) => (a.id > b.id ? 1 : -1)));
    })();
  }, [taskTypes, dispatch]);

  return (
    <Modal
      size="large"
      open={open}
      onClose={handleClose}
      className={styles.taskTypeModal}
    >
      <Modal.Header className={styles.taskTypeModalContentHeader}>
        <div>Add a task</div>
      </Modal.Header>
      <Modal.Content className={styles.taskTypeModalContent}>
        {taskTypesOptions.map(
          type =>
            data.allowedGeneralTaskTypeIds.includes(type.id) && (
              <div
                key={type.id}
                onClick={() => (
                  setSelectedType(type.id), dispatch(setSelectAddType(type))
                )}
                className={
                  selectedType !== 0 && selectedType === type.id
                    ? styles.activeTaskCard
                    : styles.taskCard
                }
              >
                <img src={type.image} alt="" className={styles.taskTypeIcon} />
                <span className={styles.caption}>{type.name}</span>
              </div>
            )
        )}
      </Modal.Content>
      <Modal.Actions className={styles.btnContainer}>
        <Button
          color="green"
          content="Cancel"
          basic
          className={styles.cancelButton}
          onClick={handleClose}
        />
        <Button
          className={styles.addButton}
          content="Save"
          disabled={selectedType === 0}
          onClick={() => navigate("/tasks/add")}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};
export default connect((state, props) => {
  return {
    activeType: state.newTasks.activeTab,
    taskTypes: state.taskCreator.taskTypes.content,
    data: state.settings.data,
    isCreating: state.settings.isCreating
  };
})(TaskTypeModal);
