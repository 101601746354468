import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { minutesToHM } from "../../../utils/timeUtils";
import Numeric from "../../Numeric";
import { cropText } from "../../../utils/string";

const BreakdownTable = ({ tasks }) => {
  return (
    <Table unstackable>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Task ID</TableHeaderCell>
          <TableHeaderCell>Task Name</TableHeaderCell>
          <TableHeaderCell>Task Type</TableHeaderCell>
          <TableHeaderCell>SubTask</TableHeaderCell>
          <TableHeaderCell>Working Time</TableHeaderCell>
          <TableHeaderCell>TimeSpend /Task/Ha </TableHeaderCell>
          <TableHeaderCell>Machinery Cost</TableHeaderCell>
          <TableHeaderCell>Labour Cost</TableHeaderCell>
          <TableHeaderCell>Alt Labour Cost</TableHeaderCell>
          <TableHeaderCell>Cost ($)/Ha</TableHeaderCell>
          <TableHeaderCell>Total Costs ($)</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {tasks.map((task, i) => (
          <TableRow key={`expanded_table_${i}`}>
            <TableCell>
              <Link
                to={`/tasks/slashing/${task.generalTaskId}`}
                target="_blank"
              >
                {`GT-${task.generalTaskId}`}
              </Link>
            </TableCell>
            <TableCell>{task.generalTaskName}</TableCell>
            <TableCell>{task.generalTaskType}</TableCell>
            <TableCell>
              {cropText(task.generalTaskSubtaskName, 50) || "-"}
            </TableCell>
            <TableCell>{minutesToHM(task.totalWorkingTimesMins)}</TableCell>
            <TableCell>
              <Numeric
                value={
                  task.totalWorkingTimesMins && task.totalAreasSize
                    ? minutesToHM(
                        task.totalWorkingTimesMins / task.totalAreasSize
                      )
                    : `0h 0m`
                }
                fractionDigits={2}
                commaSeparatorOnThousands
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={task.machineryCost ? task.machineryCost : 0}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={task.labourCost ? task.labourCost : 0}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              <Numeric
                value={task.altLabourCost ? task.altLabourCost : 0}
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
            <TableCell>
              {task.totalAreasSize ? (
                <Numeric
                  value={
                    ((task.machineryCost ? task.machineryCost : 0) +
                      (task.labourCost ? task.labourCost : 0) +
                      (task.altLabourCost ? task.altLabourCost : 0)) /
                    task.totalAreasSize
                  }
                  fractionDigits={2}
                  commaSeparatorOnThousands
                  units="$ "
                  unitsPosition="left"
                />
              ) : (
                "-"
              )}
              /Ha
            </TableCell>
            <TableCell>
              <Numeric
                value={
                  (task.machineryCost ? task.machineryCost : 0) +
                  (task.labourCost ? task.labourCost : 0) +
                  (task.altLabourCost ? task.altLabourCost : 0)
                }
                fractionDigits={2}
                commaSeparatorOnThousands
                units="$ "
                unitsPosition="left"
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const TaskSummaryRow = ({ taskSummary, showDetailedInfo }) => {
  const [isExpanded, setExpanded] = useState(false);
  const {
    tasksList,
    totalWorkingTimeInMinutes,
    totalWorkingTimesMins,
    costPerAreaPerTask,
    totalMachineryCost,
    totalLabourCost,
    totalAltLabourCost
  } = taskSummary;

  return (
    <>
      <TableRow>
        <TableCell>
          {showDetailedInfo && (
            <Button
              active={isExpanded}
              onClick={() => setExpanded(!isExpanded)}
              compact
              icon="dropdown"
            />
          )}
        </TableCell>
        <TableCell>{tasksList[0].generalTaskType}</TableCell>
        <TableCell>
          {cropText(tasksList[0].generalTaskSubtaskName, 25) || "-"}
        </TableCell>
        <TableCell>{tasksList.length}</TableCell>
        <TableCell>{minutesToHM(totalWorkingTimeInMinutes)}</TableCell>
        <TableCell>{minutesToHM(totalWorkingTimesMins)}</TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={totalMachineryCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={totalLabourCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={totalAltLabourCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={costPerAreaPerTask}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
          /Ha
        </TableCell>
        <TableCell>
          <Numeric
            fractionDigits={2}
            value={totalLabourCost + totalMachineryCost + totalAltLabourCost}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell colSpan={11}>
            <BreakdownTable tasks={tasksList} />
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

TaskSummaryRow.propTypes = {
  taskSummary: PropTypes.object,
  showDetailedInfo: PropTypes.bool,
  totalWorkingTime: PropTypes.number,
  costPerAreaPerTask: PropTypes.number
};

BreakdownTable.propTypes = {
  tasks: PropTypes.array.isRequired
};

BreakdownTable.defaultProps = {
  tasks: []
};

export default TaskSummaryRow;
