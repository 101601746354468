import React from "react";
import localStorage from "localStorage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../config";
import { setVersionChecked, purgeAllData } from "../actions/Common";
import { fetchAreasList } from "actions/Areas/areas";
import { fetchEmployeesList } from "actions/Employee/employees";
import { onlineSelector } from "../selectors/common";

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      firstPage: props.pathname
    };
  }

  async componentDidMount() {
    this.fetchMeta();
    this.fetchUrl();
  }

  componentDidUpdate(prevProps) {
    const { forceUpdate, online, actions, pathname } = this.props;
    if (forceUpdate && forceUpdate !== prevProps.forceUpdate) {
      this.refreshCacheAndReload();
    }

    if (!online && prevProps.online) {
      actions.setVersionChecked(false);
    }
    if (online && prevProps.online === false) {
      this.fetchMeta();
    }

    if (pathname !== prevProps.pathname) {
      this.fetchUrl();
    }
  }

  refreshCacheAndReload = async () => {
    if (localStorage.getItem("forceUpdated") === "true") {
      localStorage.removeItem("forceUpdated");
      this.setState({ loading: false });
      return;
    }
    const { actions } = this.props;
    console.log("Clearing cache and hard reloading...");
    try {
      await this.deleteCache();
      await localStorage.clear();
      await window.localforage.clear();
      await actions.purgeAllData();
      localStorage.setItem("forceUpdated", "true");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      console.error("Error during cache clearing or reload process:", error);
      this.setState({ loading: false });
    }
  };

  deleteCache = async () => {
    if (window.Cache) {
      try {
        const cacheNames = await caches.keys();
        for (const name of cacheNames) {
          console.log(`Deleting cache: ${name}`);
          await caches.delete(name);
        }
      } catch (error) {
        console.error("Error clearing service worker cache:", error);
      }
    }
    try {
      const openRequest = indexedDB.open("changelog_db", 1);

      openRequest.onsuccess = () => {
        const db = openRequest.result;
        if (db.objectStoreNames.contains("store")) {
          const res = db
            .transaction("store", "readwrite")
            .objectStore("store")
            .clear();
          res.onsuccess = () => {
            console.log("Changelog DB cleared successfully.");
          };
          res.onerror = event => {
            console.error("Error clearing Changelog DB:", event);
          };
        }
      };

      openRequest.onerror = () => {
        console.error("Failed to open Changelog DB.");
      };
    } catch (error) {
      console.error("Error during IndexedDB operations:", error);
    }
  };

  fetchUrl = () => {
    const { pathname, onlineReal } = this.props;
    const { firstPage } = this.state;

    if (!window.Cache) {
      console.warn("Cache API not supported.");
      return;
    }
    caches.open(config.notCached.cacheName).then(cache => {
      return cache.match(onlineReal ? pathname : firstPage).then(response => {
        if (!onlineReal && response) {
          cache.put(pathname, response.clone());
        }

        return onlineReal
          ? response ||
              fetch(pathname).then(networkResponse => {
                cache.put(pathname, networkResponse.clone());
                return networkResponse;
              })
          : response;
      });
    });
  };

  fetchMeta = () => {
    const { actions } = this.props;
    fetch("/meta.json", { cache: "no-cache" })
      .then(response => response.json())
      .then(meta => {
        const currentVersion =
          localStorage.getItem("appVersion") || meta.version;
        const latestVersion = meta.version;
        localStorage.setItem("appVersion", latestVersion);
        localStorage.setItem("currentVersion", currentVersion);
        const shouldForceRefresh = latestVersion !== currentVersion;

        if (shouldForceRefresh) {
          console.log(
            `We have a new version: ${latestVersion} (current version: ${currentVersion}). Triggering refresh...`
          );
          this.refreshCacheAndReload();
        } else {
          console.log(
            `You already have the latest version: ${latestVersion}. No refresh needed.`
          );
          this.setState({ loading: false });
          actions.setVersionChecked(true);
        }
      })
      .catch(error => {
        console.error("Failed to fetch meta.json:", error);
        this.setState({ loading: false });
        actions.setVersionChecked(false);
      });
  };

  render() {
    const { loading } = this.state;
    const { rehydrate } = this.props;
    if (loading || !rehydrate) {
      return <div>Loading application, please wait...</div>;
    }
    return this.props.children({ loading });
  }
}

CacheBuster.propTypes = {
  pathname: PropTypes.string,
  actions: PropTypes.object,
  forceUpdate: PropTypes.bool,
  online: PropTypes.bool,
  onlineReal: PropTypes.bool,
  rehydrate: PropTypes.bool,
  children: PropTypes.func
};

const mapStateToProps = state => {
  const {
    router: {
      location: { pathname }
    },
    offline: { online },
    common: { forceUpdate, rehydrate }
  } = state;

  return {
    forceUpdate,
    rehydrate,
    online,
    pathname,
    onlineReal: onlineSelector(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { setVersionChecked, purgeAllData, fetchAreasList, fetchEmployeesList },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CacheBuster);
