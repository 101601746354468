import { actionTypes } from "../../constants/TasksCreator";
import { axiosProxy } from "../../utils/axiosProxy";
import { genericAttachmentUploaderWithoutSW } from "../../utils/genericAttachmentUploader";

const baseURL = "/general_task";

export const clearFormState = () => dispatch =>
  dispatch({ type: actionTypes.CLEAR_FORM_STATE });

export const moveToStage = stage => dispatch =>
  dispatch({
    type: actionTypes.MOVE_TO_STEP,
    payload: { stage }
  });

export const moveToTheNextStage = () => dispatch =>
  dispatch({ type: actionTypes.MOVE_TO_THE_NEXT_STEP });

export const setField = (
  stage,
  fieldName,
  fieldValue,
  mandatoryFields
) => dispatch =>
  dispatch({
    type: actionTypes.SET_FIELD_VALUE,
    payload: {
      stage,
      fieldName,
      fieldValue,
      mandatoryFields
    }
  });

export const fetchTaskTypes = () => async (dispatch, getState) => {
  const url = `${baseURL}/types`;
  const {
    user: { user },
    taskCreator
  } = getState();
  const clientId = localStorage.getItem("areaClientId");
  if (
    !taskCreator.taskTypes?.content?.length ||
    !clientId ||
    clientId !== user.profile.client_id
  ) {
    try {
      dispatch({ type: actionTypes.FETCH_TASK_TYPES_START });

      const params = new URLSearchParams();
      params.append("unpaged", "true");

      const res = await axiosProxy({
        method: "GET",
        params,
        url
      });

      await dispatch({
        type: actionTypes.FETCH_TASK_TYPES,
        payload: res.data
      });
      return res.data;
    } catch (e) {
      dispatch({ type: actionTypes.FETCH_TASK_TYPES_ERROR, payload: e });
    }
  }
};

export const fetchSubTaskNames = () => async dispatch => {
  const url = `${baseURL}/sub_task_names`;

  try {
    dispatch({ type: actionTypes.FETCH_SUB_TASK_NAMES_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({
      method: "GET",
      params,
      url
    });

    await dispatch({
      type: actionTypes.FETCH_SUB_TASK_NAMES,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_SUB_TASK_NAMES_ERROR, payload: e });
  }
};

export const fetchTaskPriorities = () => async dispatch => {
  const url = `${baseURL}/priorities`;

  try {
    dispatch({ type: actionTypes.FETCH_TASK_PRIORITIES_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionTypes.FETCH_TASK_PRIORITIES,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_TASK_PRIORITIES_ERROR, payload: e });
  }
};

export const fetchTaskPaymentMethods = () => async dispatch => {
  const url = `${baseURL}/payment_methods`;

  try {
    dispatch({ type: actionTypes.FETCH_TASK_PAYMENT_METHODS_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionTypes.FETCH_TASK_PAYMENT_METHODS,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({
      type: actionTypes.FETCH_TASK_PAYMENT_METHODS_ERROR,
      payload: e
    });
  }
};

export const fetchTaskStatuses = () => async dispatch => {
  const url = `${baseURL}/statuses`;

  try {
    dispatch({ type: actionTypes.FETCH_TASK_STATUSES_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({ method: "GET", params, url });

    await dispatch({
      type: actionTypes.FETCH_TASK_STATUSES,
      payload: res.data
    });
    return res.data;
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_TASK_STATUSES_ERROR, payload: e });
  }
};

export const fetchTaskPickingTypes = () => async dispatch => {
  const url = `${baseURL}/picking_type`;

  try {
    dispatch({ type: actionTypes.FETCH_TASK_PICKING_TYPES_START });

    const params = new URLSearchParams();
    params.append("unpaged", "true");

    const res = await axiosProxy({ method: "GET", params, url });
    await dispatch({
      type: actionTypes.FETCH_TASK_PICKING_TYPES,
      payload: res.data
    });

    return res.data;
  } catch (e) {
    dispatch({ type: actionTypes.FETCH_TASK_PICKING_TYPES_ERROR, payload: e });
  }
};

export const uploadAttachment = ({
  file,
  idReplace,
  callBack,
  data,
  parentId
}) =>
  genericAttachmentUploaderWithoutSW({
    url: `${baseURL}/attachments/presign_put_url`,
    file,
    startAction: actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS_START,
    successAction: actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS,
    errorAction: actionTypes.UPLOAD_GENERAL_TASKS_ATTACHMENTS_ERROR,
    callBack,
    idReplace,
    data,
    parentId
  });

export const setGeneralTaskToForm = task => dispatch => {
  const formState = {
    taskID: task.id,
    general: {
      taskName: task.name,
      subTaskName: task.subTaskName,
      description: task.description,
      dateRange: { start: task.startAt, end: task.endAt },
      priority: task.priority,
      assignTo: task.assignedTo.map(assignee => assignee.employeeId),
      status: task.status,
      attachments: task.attachments
    },
    instructions: {
      expandedFarmsIds: [],
      expandedAreasIds: [],
      treeSelectSearchFilter: "",
      farms: task.areas.length
        ? [
            ...new Map(
              task.areas.map(({ area }) => [area.farm.id, area.farm])
            ).values()
          ]
        : [],
      areas: task.areas.map(({ area }) => area),
      variety: task.variety,
      crop: task.variety && task.variety.crop,
      numberOfEmployees: task.numberOfEmployees,
      equipment: task.equipments.map(e => e.machineryId),
      paymentMethod: task.paymentMethod
    },
    switchable: {
      round: task.round,
      subType: task.generalTaskSubTypeId,
      comments: task.comments,
      timeBudgeting: task.timeBudgeting,
      perRow: task.timeBudgetingPerRow,
      perPlant: task.timeBudgetingPerPlant,
      pickingType: task.harvestUnitPickingTypeId,
      harvestUnitId: task.harvestUnitId,
      unitPrice: task.unitPrice,
      harvestQualityCheck: null,
      checksType: [],
      size: task.size,
      runningTimeHours: Math.floor(task.runningTimeMin / 60),
      runningTimeMinutes:
        task.runningTimeMin - Math.floor(task.runningTimeMin / 60) * 60,
      flowRate: task.flowRate,
      totalIrrigationWater: task.totalIrrigationWater
    }
  };

  dispatch({ type: actionTypes.SET_FORM_STATE, payload: formState });
};

export const deleteAttachment = id => dispatch =>
  dispatch({ type: actionTypes.DELETE_ATTACHMENT, payload: id });

export const setTaskType = id => dispatch =>
  dispatch({ type: actionTypes.SET_TASK_TYPE, payload: id });

export const clearError = () => ({ type: actionTypes.CLEAR_ERROR });
