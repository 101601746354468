import { createSelector } from "reselect";
import _ from "lodash";
import {
  ALT_LABOUR_CONTRACTOR_TYPE,
  CONTRACTOR_TYPE
} from "constants/GeneralTasks/costEntering";

const activeTask = (state, props) =>
  props && props.activeTask ? props.activeTask : null;

const tasksSelector = (state, props) =>
  props && props.generalTasks ? props.generalTasks.data.content : null;

export const costTypes = state => state.costEntering.data;
export const employeeList = state => state.employee.list;
export const subTypes = state => state.costEntering.types;
export const taskNames = state => state.generalTasks.taskNames;
export const tasksId = state => state.newTasks.taskIDs;
export const taskPaymentMethods = state => state.newTasks.taskPaymentMethods;
export const taskTypesList = state => state.taskCreator.taskTypes.content;
export const machinery = state => state.machinery.data.content;
export const generalTask = state => state.newTasks;
export const generalTasksList = state => state.newTasks.list;
const areasList = state => state.areas.list.content;

export const activeTaskSelector = createSelector(
  [tasksSelector, activeTask],
  (selectTasks, selectActiveTask) => {
    return selectActiveTask && selectTasks
      ? selectTasks.find(
          task =>
            task.id === Number(selectActiveTask) ||
            task._id === selectActiveTask
        )
      : null;
  }
);

export const generalTaskOptionsSelector = createSelector(
  [taskTypesList],
  types =>
    types
      ? types.map(({ id, name }) => ({
          key: id,
          text: name,
          value: name
        }))
      : []
);
export const generalTasksOptionsSelector = createSelector(
  [generalTasksList],
  types => {
    return types.content.length
      ? types.content.map(({ id, name }) => ({
          key: id,
          text: `GT-${id}`,
          value: id
        }))
      : [];
  }
);
export const generalTasksFilterOptions = createSelector(
  [generalTasksList],
  types => {
    return {
      options: types.content.length
        ? types.content.map(({ id, name }) => ({
            key: id,
            label: `GT-${id}`,
            value: id
          }))
        : []
    };
  }
);
export const costTypeFilterOptions = createSelector(
  [costTypes],
  ({ content }) => ({
    options: CONTRACTOR_TYPE
      ? CONTRACTOR_TYPE.map(({ key, text, value }) => ({
          key,
          label: text,
          value
        }))
      : []
  })
);

export const generalTasksOptions = createSelector([generalTasksList], types => {
  return {
    options: types.content.length
      ? types.content.map(({ id, name }) => ({
          key: id,
          text: `GT-${id} ${name}`,
          value: id
        }))
      : []
  };
});

export const altLabourCostTypeFilterOptions = createSelector(
  [costTypes],
  ({ content }) => ({
    options: ALT_LABOUR_CONTRACTOR_TYPE
      ? ALT_LABOUR_CONTRACTOR_TYPE.map(({ key, text, value }) => ({
          key,
          label: text,
          value
        }))
      : []
  })
);
export const activityFilterOptions = createSelector([taskTypesList], types => ({
  options: types
    ? types.map(({ id, name }) => ({
        key: id,
        label: name,
        value: id
      }))
    : []
}));
export const activityOptions = createSelector(
  [generalTasksList],
  ({ content }) =>
    content
      ? Array.from(
          new Set(
            content
              .map(({ type }) => ({
                key: type.id,
                text: type.name,
                value: type.id
              }))
              .map(s => s.key)
          )
        ).map(key => ({
          key: key,
          value: content.find(({ type }) => type.id === key).type.name,
          text: content.find(({ type }) => type.id === key).type.name
        }))
      : []
);

export const subTypeSelector = createSelector([subTypes], ({ content }) =>
  content
    ? content
        .filter(item => item != "")
        .map(item => ({
          key: item,
          text: (
            item
              .toLowerCase()
              .charAt(0)
              .toUpperCase() + item.toLowerCase().slice(1)
          ).replace(/\s/g, "_"),
          value: item
        }))
    : []
);

export const activeItemSelector = createSelector(
  [generalTask],
  generalTaskData => {
    const { activeItem, data } = generalTaskData;
    if (activeItem?.id) {
      return data.content.find(({ id }) => activeItem?.id === id);
    }
    return null;
  }
);

export const generalTasksIdsOptions = createSelector([tasksId], ids => {
  return ids?.content?.length
    ? ids.content.map(id => ({
        key: id,
        text: `GT-${id}`,
        value: id
      }))
    : [];
});

export const generalTasksAreas = createSelector(
  [generalTasksList, areasList],
  (tasks, areas) =>
    tasks.content.reduce(
      (prev, task) => ({
        ...prev,
        [task.id]: areas
          .filter(({ id }) =>
            task.areas.map(({ areaId }) => areaId).includes(id)
          )
          .map(({ id, name, parent }) => ({
            key: `area_${id}`,
            text: parent ? `${parent.name}/${name}` : name,
            value: id
          }))
      }),
      {}
    )
);

export const generalTasksNameOptions = createSelector(
  [generalTasksList],
  list => {
    return {
      options: list.content.length
        ? list.content.map(({ id, name }) => ({
            key: id,
            label: name,
            value: id
          }))
        : []
    };
  }
);
export const generalTasksAssignTo = createSelector([employeeList], list => {
  return {
    options: list.content.length
      ? list.content
          .filter(e => !e.archived)
          .map(item => ({
            key: item.id,
            label: `${item.firstName} ${item.lastName}`,
            value: item.id
          }))
      : []
  };
});

export const contractorFilterOptions = createSelector(
  [employeeList],
  employees => ({
    options: employees.content.length
      ? employees.content
          .filter(item => item.type === "CONTRACTOR")
          .map(({ id, firstName, lastName }) => ({
            key: id,
            label: `${firstName} ${lastName}`,
            value: id
          }))
      : []
  })
);
export const supervisorsFilterOptions = createSelector(
  [employeeList],
  employees => ({
    options: employees.content.length
      ? employees.content
          .filter(item => item.type === "SUPERVISOR")
          .map(({ id, firstName, lastName }) => ({
            key: id,
            label: `${firstName} ${lastName}`,
            value: id
          }))
      : []
  })
);
export const generalTasksPaymentMethod = createSelector(
  [taskPaymentMethods],
  list => {
    return {
      options: list?.content?.length
        ? list?.content.map(item => ({
            key: item,
            label: (
              item
                .toLowerCase()
                .charAt(0)
                .toUpperCase() + item.toLowerCase().slice(1)
            ).replace(/_/g, " "),
            value: item
          }))
        : []
    };
  }
);

export const tasksPriorityFilter = createSelector([], () => {
  return {
    options: [
      {
        key: "HIGH",
        value: "HIGH",
        label: "High"
      },
      {
        key: "MEDIUM",
        value: "MEDIUM",
        label: "Medium"
      },
      {
        key: "LOW",
        value: "LOW",
        label: "Low"
      }
    ]
  };
});

export const machineryFilterOptions = createSelector(
  [machinery],
  employees => ({
    options: employees.map(({ id, name }) => ({
      key: id,
      label: `${name}`,
      value: id
    }))
  })
);
