import { actionType } from "../../constants/WorkingTime";

const initialState = {
  tasks: {
    content: [],
    isFetching: false
  },
  workingTimes: {
    content: [],
    isFetching: false
  },
  clockIn: {
    content: [],
    isFetching: false
  },
  activeItem: {
    id: null,
    items: []
  },
  activeTab: {
    tab: "IN_PROGRESS",
    isEditing: false
  },
  error: null
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.FETCH_TASKS_START:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isFetching: true
        }
      };
    case actionType.SET_TASKS:
      return {
        ...state,
        tasks: {
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.FETCH_TASKS_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.GET_WORKING_TIME_START:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: true
        }
      };
    case actionType.GET_WORKING_TIME:
      return {
        ...state,
        workingTimes: {
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.GET_WORKING_TIME_FAIL:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.GET_CLOCK_IN_START:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          isFetching: true
        }
      };

    case actionType.GET_CLOCK_IN:
      return {
        ...state,
        clockIn: {
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.GET_CLOCK_IN_FAIL:
      return {
        ...state,
        clockIn: {
          ...state.clockIn,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.UPDATE_WORKING_TIME_START:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: true
        }
      };
    case actionType.UPDATE_WORKING_TIME:
      return {
        ...state,
        workingTimes: {
          content: state.workingTimes.content.map(wt =>
            wt.id === action.payload.id ? action.payload : wt
          ),
          isFetching: false
        }
      };
    case actionType.UPDATE_WORKING_TIME_FAIL:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.UPDATE_WORKING_TIME_STATUS_START:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: true
        }
      };
    case actionType.UPDATE_WORKING_TIME_STATUS:
      return {
        ...state,
        workingTimes: {
          content: state.workingTimes.content.map(wt => {
            const updatedWorkingTime = action.payload.find(
              ({ id }) => id === wt.id
            );

            return {
              ...wt,
              ...(updatedWorkingTime && {
                approved: updatedWorkingTime.approved,
                approvedBy: updatedWorkingTime.approvedBy
              })
            };
          }),
          isFetching: false
        }
      };

    case actionType.UPDATE_WORKING_TIME_STATUS_FAIL:
      return {
        ...state,
        workingTimes: {
          ...state.workingTimes,
          isFetching: false
        },
        error: action.payload
      };

    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          ...action.payload,
          error: null
        }
      };
    case actionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: {
          tab: action.payload,
          error: null
        }
      };
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    default:
      return state;
  }
};
