import React from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Popup
} from "semantic-ui-react";
import styles from "./Sidebar.module.css";
import { useDispatch } from "react-redux";
import TaskDetails from "./components/TaskDetails";
import ClockDetails from "./components/ClockDetails";
import CollapsibleBlock from "components/CollapsibleBlock";
import { updateWorkingTimeStatus } from "actions/WorkingTime";

function Sidebar({
  setSubRowsIndex,
  subRowsIndex,
  activeItem = {},
  setClockOutOpen,
  setSideBarOpen,
  onClose,
  setActiveItemId,
  employees
}) {
  const dispatch = useDispatch();
  const aprovedAll = () => {
    const wtId = activeItem?.items?.map(item => item.id);
    dispatch(updateWorkingTimeStatus(wtId));
  };
  return (
    <Container fluid className={styles.root}>
      <Grid className={styles.sidebarHeader}>
        <Grid.Row columns={"equal"} verticalAlign="middle">
          <Grid.Column width={13} className={styles.headerColumn}>
            <Header className={styles.name} as="h1">
              {`GT-${activeItem?.id} details`}
            </Header>
          </Grid.Column>
          <Grid.Column className={styles.btnWrapper} width={3} floated="right">
            <Popup
              inverted
              position="bottom center"
              content={"Edit"}
              size="tiny"
              trigger={
                <Button
                  onClick={() => setClockOutOpen(true)}
                  className={styles.editButton}
                  floated="right"
                  icon={<Icon className="edit" />}
                  size="small"
                />
              }
            />
            <Popup
              inverted
              position="bottom center"
              content={"Close"}
              size="tiny"
              trigger={
                <Button
                  onClick={() => {
                    dispatch(setActiveItemId(null));
                    setSideBarOpen(false);
                    onClose();
                  }}
                  className={styles.closeButton}
                  floated="right"
                  icon={<Icon className="close" />}
                  size="small"
                />
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid className={styles.infoContainer}>
        <Grid.Row columns={1}>
          <Grid.Column className={styles.generalInfoContainer}>
            <TaskDetails activeItem={activeItem} employees={employees} />

            <CollapsibleBlock
              title="Clock in - Clock out details"
              className={styles.blockContainer}
            >
              {activeItem?.items?.map((item, index) => {
                return (
                  <ClockDetails
                    setSubRowsIndex={setSubRowsIndex}
                    setClockOutOpen={setClockOutOpen}
                    index={index}
                    activeItem={item}
                    subRowsIndex={subRowsIndex}
                  />
                );
              })}
            </CollapsibleBlock>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={styles.buttonContainer}>
        <Button
          onClick={() => (aprovedAll(), onClose())}
          className={styles.approvedBtn}
        >
          Approve
        </Button>
      </div>
    </Container>
  );
}

export default Sidebar;
