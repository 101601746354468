import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
  Button,
  Checkbox,
  Grid,
  Icon,
  Container,
  Modal
} from "semantic-ui-react";
import * as XLSX from "xlsx";
import moment from "moment";
import NewCostForm from "./NewCostForm";
import { CONTRACTOR_TYPE_ACCESSER } from "constants/GeneralTasks/costEntering";
import styles from "./NewCost.module.css";

const NewCost = ({ errors }) => {
  const { values, setFieldValue, validateForm } = useFormikContext();
  const [allChecked, setAllChecked] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const areasList = useSelector(state => state.areas.list.content);
  const fileInputRef = useRef();

  const costsListCheck = values.costs?.length;
  const addProduct = () => {
    setFieldValue(
      "costs",
      values.costs && [
        ...values.costs,
        {
          costType: "",
          areaData: {
            selectedAreas: [],
            treeSelectSearchFilter: ""
          },
          costSubType: "",
          units: null,
          rate: null,
          notes: "",
          startAt: "",
          endAt: ""
        }
      ]
    );
  };

  const handleSelectAll = event => {
    setAllChecked(!allChecked);
    setSelectedItem(values.costs && values.costs.map((item, index) => index));
    allChecked && setSelectedItem([]);
  };

  const checkHandler = index => {
    selectedItem.includes(index)
      ? setSelectedItem(selectedItem.filter(i => i != index))
      : setSelectedItem([...selectedItem, index]);
  };
  const onDeleteByIndex = () => {
    var costListInObject = values.costs.reduce((accumulator, value, index) => {
      return { ...accumulator, [index]: value };
    }, {});
    selectedItem.forEach(function(k) {
      delete costListInObject[k];
    });
    const newData = Object.values(costListInObject);
    setFieldValue("costs", newData);
    setSelectedItem([]);
    if (values.costs.length === selectedItem.length) {
      setFieldValue(
        "costs",
        values.costs && [
          {
            costType: "",
            areaData: {
              selectedAreas: [],
              treeSelectSearchFilter: ""
            },
            costSubType: "",
            units: null,
            rate: null,
            notes: "",
            startAt: "",
            endAt: ""
          }
        ]
      );
    }
    setShowDeleteModal(false);
  };

  const handleFileUpload = e => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      const workbook = XLSX.read(event.target.result, {
        type: "binary",
        raw: true
      });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      const costs = [];

      sheetData.forEach(area => {
        const [startAt, endAt = ""] = area["Date/Period"]?.split("-");

        const selectedFarmId = areasList.find(a => a.farm.name === area.Farm);
        const farmAllAreaName = areasList
          .filter(a => a.farm.name === area.Farm && !a.archived)
          .map(item => item.name);
        const areasName = area.Area.split(",").map(item => item.trim());

        const filtredAreas = areasList.filter(a =>
          (selectedFarmId ? farmAllAreaName : areasName).some(name => {
            return name.includes("/")
              ? name.split("/")[1] === a.name
              : name === a.name;
          })
        );
        const selectedAreas = [];
        filtredAreas.forEach(areaObj => {
          if (areaObj) {
            const farmBlocks = areasList.filter(
              a => a.type === "BLOCK" && a.farm.id === areaObj.farm.id
            );

            if (areaObj.type === "PATCH") {
              const parentBlockPatches = areasList.filter(
                a => a.type === "PATCH" && a.parent.id === areaObj.parent.id
              );

              selectedAreas.push({
                id: areaObj.id,
                type: "patch",
                farmId: areaObj.farm.id,
                areaData: {
                  id: areaObj.id,
                  blockId: areaObj.parent.id,
                  type: "patch",
                  farmId: areaObj.farm.id,
                  checked: false,
                  label: areaObj.name,
                  value: [areaObj]
                }
              });

              if (parentBlockPatches.length === 1) {
                selectedAreas.push({
                  id: areaObj.parent.id,
                  type: "block",
                  farmId: areaObj.farm.id,
                  areaData: {
                    id: areaObj.parent.id,
                    type: "block",
                    farmId: areaObj.farm.id,
                    expanded: true,
                    checked: false,
                    label: areaObj.parent.name,
                    children: [
                      {
                        id: areaObj.id,
                        blockId: areaObj.parent.id,
                        checked: false,
                        className: "",
                        farmId: areaObj.farm.id,
                        label: areaObj.name,
                        type: "patch",
                        value: [areaObj]
                      }
                    ],
                    value: [areaObj]
                  }
                });
              }
            } else {
              const blockPatches = areasList.filter(
                a => a.type === "PATCH" && a.parent.id === areaObj.id
              );

              selectedAreas.push(
                ...[
                  {
                    id: areaObj.id,
                    type: "block",
                    farmId: areaObj.farm.id,
                    areaData: {
                      id: areaObj.id,
                      type: "block",
                      farmId: areaObj.farm.id,
                      expanded: false,
                      checked: false,
                      label: areaObj.name,
                      children: blockPatches.length
                        ? blockPatches.map(patch => ({
                            blockId: areaObj.id,
                            checked: false,
                            className: "",
                            farmId: areaObj.farm.id,
                            label: patch.name,
                            type: "patch",
                            value: [patch]
                          }))
                        : [],
                      value: blockPatches.length ? [areaObj] : []
                    }
                  },
                  ...(blockPatches.length
                    ? blockPatches.map(patch => ({
                        id: patch.id,
                        type: "patch",
                        farmId: patch.farm.id,
                        areaData: {
                          id: patch.id,
                          blockId: patch.parent.id,
                          type: "patch",
                          farmId: patch.farm.id,
                          checked: false,
                          className: "",
                          label: patch.name,
                          value: [patch]
                        }
                      }))
                    : [])
                ]
              );
            }

            if (farmBlocks.length === 1) {
              selectedAreas.push({
                farmId: areaObj.farm.id,
                type: "farm"
              });
            }
          }
        });

        if (area.Farm && area.Farm !== "-") {
          selectedAreas.push({ farmId: selectedFarmId?.farm.id, type: "farm" });
        }

        costs.push({
          costType: CONTRACTOR_TYPE_ACCESSER[area["Cost type"]],
          areaData: {
            selectedAreas,
            treeSelectSearchFilter: ""
          },
          costSubType: area.Type,
          units: Number(area.Units),
          rate: Number(area.Rate),
          notes: area.Notes === "-" ? "" : area.Notes,
          startAt: moment(startAt, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00"),
          endAt:
            endAt &&
            moment(endAt, "DD/MM/YYYY")
              .add(1, "day")
              .format("YYYY-MM-DDT00:00:00")
        });
      });

      setFieldValue("costs", costs);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <Container className={styles.root}>
      <Modal
        className={styles.deleteModal}
        size="mini"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Content>
          <p>Delete {selectedItem.length} cost type?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            className={styles.cancelDltBtn}
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button
            className={styles.deleteBtn}
            positive
            onClick={() => onDeleteByIndex()}
          >
            Delete
          </Button>
        </Modal.Actions>
      </Modal>
      <div className={styles.deleteButtonContainer}>
        <Button
          size="small"
          floated="right"
          className={styles.importButtonForPage}
          onClick={() => fileInputRef.current.click()}
        >
          <div className={styles.iconWrapperForPage}>
            <Icon name="upload" />
            Import
          </div>
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={handleFileUpload}
        />
        <Button
          className={styles.delete}
          disabled={!selectedItem.length || costsListCheck <= 1}
          onClick={() => setShowDeleteModal(true)}
        >
          <div className={styles.deleteButtonIconWrapper}>
            <Icon className={styles.deleteButtonIcon} name="trash alternate" />
            Delete
          </div>
        </Button>
      </div>
      <Grid className={styles.wrapperRow}>
        <Grid.Row className={styles.labelRow}>
          <Grid.Column width={2} className={styles.costtypeLabel}>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                disabled={!costsListCheck}
                indeterminate={
                  selectedItem.length != values.costs?.length &&
                  selectedItem.length >= 1
                }
                checked={selectedItem.length === values.costs?.length}
                onChange={event => handleSelectAll(event)}
              />
            </div>
            Cost type
          </Grid.Column>
          <Grid.Column width={2} className={styles.area}>
            Area
          </Grid.Column>
          <Grid.Column width={2} className={styles.type}>
            Type
          </Grid.Column>
          <Grid.Column width={2} className={styles.units}>
            Units
          </Grid.Column>
          <Grid.Column width={2} className={styles.rate}>
            Rate ($)
          </Grid.Column>
          <Grid.Column width={1} className={styles.total}>
            Total ($)
          </Grid.Column>
          <Grid.Column width={2} className={styles.notes}>
            Notes
          </Grid.Column>
          <Grid.Column className={styles.datePeriod}>Date/Period</Grid.Column>
          <Grid.Column width={1}></Grid.Column>
        </Grid.Row>
      </Grid>
      <div className={values.costs && styles.formContainer}>
        {values.costs &&
          values.costs.map((cost, index) => (
            <NewCostForm
              key={`${cost.costType}-${index}`}
              addProduct={addProduct}
              errors={errors}
              selectedItem={selectedItem}
              checkHandler={checkHandler}
              costData={cost}
              index={index}
              costsListCheck={costsListCheck}
            />
          ))}
      </div>
      <div className={styles.newRowbuttonContainer}>
        <Button
          type="submit"
          className="button-text color-green"
          onClick={addProduct}
        >
          + New row
        </Button>
      </div>
    </Container>
  );
};

export default NewCost;
