import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Table, Icon } from "semantic-ui-react";
import classNames from "classnames";
import moment from "moment";
import styles from "./TableRow.module.css";
import Loader from "../../../../../components/Loader";
import Numeric from "../../../../../components/Numeric";
import { fetchHourlyPayrollsByEmployeeId } from "../../../../../actions/HourlyPayroll/hourlyPayroll";
import { minutesToHM } from "../../../../../utils/timeUtils";

const TableRow = ({
  isStriped,
  location,
  payroll: {
    employeeId,
    employeeName,
    contractor,
    workingSeconds,
    currentRate,
    totalPay,
    expandedData
  },
  dateFrom,
  dateTo
}) => {
  const dispatch = useDispatch();
  const [showAdditionalRow, setShowAdditionalRow] = useState(false);

  useEffect(() => {
    if (showAdditionalRow && !expandedData) {
      dispatch(
        fetchHourlyPayrollsByEmployeeId({
          employeeId,
          from: dateFrom,
          to: dateTo
        })
      );
    }
  }, [showAdditionalRow]);

  useEffect(() => {
    setShowAdditionalRow(false);
  }, [location.hash]);

  const onExpandClick = () => {
    setShowAdditionalRow(!showAdditionalRow);
  };

  const formTaskLink = taskId => {
    const [type, id] = taskId.split("-");

    switch (type) {
      case "GT":
        return `/tasks/slashing/${id}`;
      case "ST":
        return `/spray_diary/id=${id}/${id}#sort=date,desc`;
      default:
        return "/";
    }
  };

  return (
    <>
      <Table.Row
        className={classNames({
          [styles.stripedRow]: isStriped,
          [styles.expandedRow]: showAdditionalRow
        })}
      >
        <Table.Cell
          className={classNames(styles.iconCell, {
            [styles.drawGreenLine]: showAdditionalRow
          })}
        >
          <Icon
            className={classNames(styles.toggleIcon, "tuf-chevron-down", {
              [styles.toggleIconTurned]: showAdditionalRow
            })}
            onClick={onExpandClick}
          />
        </Table.Cell>
        <Table.Cell>{employeeName}</Table.Cell>
        <Table.Cell>{contractor || "—"}</Table.Cell>
        <Table.Cell>{minutesToHM(workingSeconds / 60)}</Table.Cell>
        <Table.Cell>
          <Numeric
            value={currentRate}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
        <Table.Cell>
          <Numeric
            value={totalPay}
            fractionDigits={2}
            commaSeparatorOnThousands
            units="$ "
            unitsPosition="left"
          />
        </Table.Cell>
      </Table.Row>
      {showAdditionalRow && expandedData && (
        <Table.Row
          className={classNames(styles.additionalRow, styles.expandedRow)}
        >
          <Table.Cell className={styles.drawGreenLine} />
          <Table.Cell colSpan={5}>
            <Table
              basic="very"
              unstackable
              className={styles.additionalInfoTable}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Task ID</Table.HeaderCell>
                  <Table.HeaderCell>Task type</Table.HeaderCell>
                  <Table.HeaderCell>Working time</Table.HeaderCell>
                  <Table.HeaderCell>Rate ($/H)</Table.HeaderCell>
                  <Table.HeaderCell>Total Pay</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {expandedData.isFetching ? (
                  <Table.Row>
                    <Table.Cell>
                      <Loader className={styles.loader} />
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  expandedData.items.length > 0 &&
                  expandedData.items.map(
                    (
                      { date, taskId, taskType, workingSeconds, rate },
                      index
                    ) => (
                      <Table.Row key={`expanded_row_${taskId}_${index}`}>
                        <Table.Cell>
                          {moment(date).format("DD/MM/YYYY")}
                        </Table.Cell>
                        <Table.Cell>
                          {taskId ? (
                            <Link to={formTaskLink(taskId)} target="_blank">
                              {taskId}
                            </Link>
                          ) : (
                            "—"
                          )}
                        </Table.Cell>
                        <Table.Cell>{taskType}</Table.Cell>
                        <Table.Cell>
                          {minutesToHM(workingSeconds / 60)}
                        </Table.Cell>
                        <Table.Cell>
                          <Numeric
                            value={rate}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Numeric
                            value={(workingSeconds / 3600) * rate}
                            fractionDigits={2}
                            commaSeparatorOnThousands
                            units="$ "
                            unitsPosition="left"
                          />
                        </Table.Cell>
                      </Table.Row>
                    )
                  )
                )}
              </Table.Body>
            </Table>
          </Table.Cell>
        </Table.Row>
      )}
    </>
  );
};

TableRow.propTypes = {
  isStriped: PropTypes.bool,
  location: PropTypes.object.isRequired,
  payroll: PropTypes.shape({
    employeeId: PropTypes.string,
    employeeName: PropTypes.string,
    contractor: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null])
    ]),
    workingSeconds: PropTypes.number,
    numberOfPieces: PropTypes.number,
    totalPay: PropTypes.number,
    expandedData: PropTypes.shape({
      date: PropTypes.string,
      taskId: PropTypes.string,
      taskType: PropTypes.string,
      workingSeconds: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.oneOf([null])
      ]),
      unitType: PropTypes.string,
      numberOfPieces: PropTypes.number,
      rate: PropTypes.number
    })
  }),
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string
};

export default TableRow;
